<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item">Ordens de serviços</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Ordens de serviços</h1>
    <!-- END page-header -->
    <panel :noButton="true">
      <div
        slot="header"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <span>Listagem de ordens de serviços </span>
        <div class="option-input d-flex">
          Concluídos
          <div class="form-check form-switch d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="concluidos"
            />
            <label class="form-check-label"></label>
          </div>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :lineNumbers="false"
        :isLoading="isLoad"
        :styleClass="'vgt-table striped'"
        :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
        :pagination-options="pageOptions"
      >
        <template #loadingContent>
          <div class="text-center">
            <b-spinner
              class="align-middle"
              style="width: 2rem; height: 2rem"
            ></b-spinner>
            <strong style="margin-left: 0.5rem">Carregando...</strong>
          </div>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            {{ props.row.id.toString().padStart(6, '0') }}
          </span>
          <span v-if="props.column.field == 'nome_arquivo'">
            {{ props.row.nome_arquivo.toString() }}
          </span>
          <span v-if="props.column.field == 'total_paginas'">
            {{ props.row.total_paginas.toString() }}
          </span>
          <span v-if="props.column.field == 'quantidade_caixas'">
            {{ props.row.quantidade_caixas.toString() }}
          </span>
          <span v-if="props.column.field == 'dt_postagem'">
            {{ props.row.dt_postagem.toString() }}
          </span>
          <span v-if="props.column.field == 'status'">
            {{ props.row.status.toString() }}
          </span>
          <span v-if="props.column.field == 'cliente'">
            {{ props.row.cliente.nome.toString() }}
          </span>
          <span v-if="props.column.field == 'produto'">
            {{ props.row.produto.nome.toString() }}
          </span>

          <span v-if="props.column.field == 'acao'">
            <div style="width: 230px">
              <template v-if="props.row.dt_backup === null">
                <button
                  v-if="checkFuncionalidade(1)"
                  class="btn btn-success me-2"
                  @click="
                    detalhesImpressao(props.row.id.toString().padStart(6, '0'))
                  "
                >
                  Impressão
                </button>
                <button
                  v-if="checkFuncionalidade(4)"
                  class="btn btn-info me-2"
                  @click="
                    detalhesEnvelopamento(
                      props.row.id.toString().padStart(6, '0')
                    )
                  "
                >
                  Envelop.
                </button>
              </template>
              <template v-if="props.row.dt_envio_arq_retorno !== null">
                <button
                  v-if="checkFuncionalidade(19)"
                  class="btn btn-warning"
                  @click="enviarPdf(props.row)"
                >
                  PDF
                </button>
              </template>
            </div>
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>

<script>
import axios from 'axios'
import CheckFunc from '../../../mixins/CheckFunc'

export default {
  components: {},
  data() {
    return {
      isLoad: false, // Corrigido para 'isLoad'
      concluidos: false,

      columns: [
        {
          label: 'OS',
          field: 'id'
        },
        {
          label: 'Arquivo',
          field: 'nome_arquivo'
        },
        {
          label: 'Qtd Páginas',
          field: 'total_paginas'
        },
        {
          label: 'Qtd Caixas',
          field: 'quantidade_caixas'
        },
        {
          label: 'Dt Postagem',
          field: 'dt_postagem'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Cliente',
          field: 'cliente'
        },
        {
          label: 'Produto',
          field: 'produto'
        },
        {
          label: 'Ações',
          field: 'acao',
          tdClass: 'text-center',
          width: '150px'
        }
      ],
      rows: []
    }
  },
  mixins: [CheckFunc],
  methods: {
    pageChanged(newPage) {
      console.log(newPage)
    },
    detalhesEnvelopamento(id) {
      this.$router.push({ name: 'ordem.envelopamento', params: { id: id } })
    },
    detalhesImpressao(id) {
      this.$router.push({ name: 'ordem.impressao', params: { id } })
    },
    enviarPdf(obj) {
      let Swal = this.$swal
      Swal.fire({
        title: 'Envio de PDFs',
        text:
          'Tem certeza que deseja enviar os PDFs do arquivo \n' +
          obj.nome_arquivo,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .get('producao/envia-pdf/' + obj.id)
            .then(res => {
              return res.data
            })
            .then(res => {
              return res
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Erro: ${error.response.data.mensagem}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Arquivo enviado com sucesso!',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    },
    reloadDados() {
      setInterval(() => {
        if (this.$route.name !== 'ordem.listagem') return
        axios
          .get(`producao/ordem-servico?concluidos=${this.concluidos}`)
          .then(res => {
            return res.data.dados
          })
          .then(res => {
            this.items = res.data
            this.load = false
          })
      }, 5000)
    },
    async loadItens() {
      this.isLoad = true // Corrigido para 'isLoad'
      await axios
        .get(this.apiUrl)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.isLoad = false // Corrigido para 'isLoad'
        })
    }
  },
  mounted() {
    this.loadItens()
    // this.reloadDados()
  },
  computed: {
    apiUrl() {
      return `producao/ordem-servico?per_page=100000000000&concluidos=${this.concluidos}`
    },
    pageOptions() {
      return {
        isBusy: false,
        enabled: true,
        mode: 'records',
        perPage: 50,
        position: 'bottom',
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params =>
          `Quantidade de linhas ${
            params.lastRecordOnPage - params.firstRecordOnPage + 1
          } - Página ${params.currentPage} de ${
            params.totalPage
          } - Total Ordens de serviços ${params.totalRecords}`
        // infoFn: params =>
        //   `Página atual ${params.firstRecordOnPage} on page ${params.lastRecordOnPage} on page ${params`
      }
    }
  },
  watch: {
    concluidos() {
      this.loadItens()
    },
    currentPage() {
      this.loadItens()
    }
  }
}
</script>
